
  import { Component, Vue, Prop } from "vue-property-decorator";

  @Component({
    components: {
      TextPart: () => import("@/components/dossier/property-info/text.vue"),
      AreaPart: () => import("@/components/dossier/property-info/area.vue"),
      AreaSqPart: () => import("@/components/dossier/property-info/area_sq.vue"),
      YesNoPart: () => import("@/components/dossier/property-info/yes-no.vue"),
    },
  })
  export default class DossierPartPropertyInfo extends Vue {
    @Prop() property?: Property;

    get propertyType() {
      return this.property?.characteristics?.type ?? "";
    }

    get hasSalesPromise() {
      if (!this.property) {
        return;
      }

      return (
        this.property.characteristics.sales_promise &&
        (this.property.characteristics.sales_promise.hasOwnProperty("electricity_inspection_conform") ||
          this.property.characteristics.sales_promise.hasOwnProperty("fuel_oil_tank_compliant") ||
          this.property.characteristics.sales_promise.hasOwnProperty("asbestos_present") ||
          this.property.characteristics.sales_promise.hasOwnProperty("struck_by_red_line") ||
          this.property.characteristics.sales_promise.hasOwnProperty("epc_label") ||
          this.property.characteristics.sales_promise.hasOwnProperty("epc_score"))
      );
    }
  }
